import React, { FC, useRef } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { Camera } from '@wix/wix-ui-icons-common/on-stage';
import { Avatar } from 'wix-ui-tpa/cssVars';
import { MediaPlatformImage } from '@wix/members-domain-ts';

import {
  DataHook,
  ElementId,
  InjectedClassNameProps,
  Nullable,
  ProfileImage,
  ProfileImageSize,
  ProfileLayout,
} from '../../../types';
import styles from './AvatarInput.scss';
import classNames from 'classnames';
import { getElementId } from '../../../services/html-attributes';
import { acceptableImageExtensions } from '../../../constants/image';

type AvatarProps = {
  url: Nullable<string | MediaPlatformImage>;
  imageType: ProfileImage;
  avatarSize?: ProfileImageSize;
  profileLayout?: ProfileLayout;
  canUpload?: boolean;
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  isMobile?: boolean;
} & InjectedClassNameProps;

const AvatarInput: FC<React.PropsWithChildren<AvatarProps>> = ({
  url,
  imageType,
  className = '',
  name,
  onClick,
  canUpload,
  avatarSize,
  profileLayout,
  isMobile = false,
}) => {
  const { t } = useTranslation();
  const { widgetId } = useEnvironment();
  const inputRef = useRef<HTMLInputElement>(null);

  const inputId = getElementId(widgetId, ElementId.ProfilePhotoInput);
  const imageURL = typeof url === 'object' ? url?.file_name : url;

  if (imageType === ProfileImage.None) {
    return null;
  }

  const isAvatarSquare = imageType === ProfileImage.Square;

  const sizeClasses = {
    [styles.small]: avatarSize === ProfileImageSize.Small,
    [styles.large]: avatarSize === ProfileImageSize.Large,
  };

  const wrapperClasses = {
    [styles.wrapper]: true,
    [styles.onHover]: canUpload,
    [styles.fullWidth]: profileLayout === ProfileLayout.FullWidth,
    [styles.card]: profileLayout === ProfileLayout.Card,
    [styles.mobile]: isMobile,
    [styles.squareOverlay]: isAvatarSquare,
  };

  const avatarClasses = {
    [styles.avatar]: true,
    [styles.squareAvatar]: isAvatarSquare,
  };

  const ariaLabel = imageURL
    ? t('profile-widget.change-profile-photo')
    : t('profile-widget.add-profile-photo.aria-label');

  return (
    <div className={classNames(sizeClasses)}>
      <button
        className={classNames(wrapperClasses, className)}
        onClick={() => inputRef.current?.click()}
        aria-label={ariaLabel}
        disabled={!canUpload}
      >
        <Avatar
          name={name}
          src={imageURL}
          data-hook={DataHook.ProfilePhoto}
          className={classNames(avatarClasses)}
        />
        {canUpload && (
          <div>
            <input
              id={inputId}
              className={styles.uploadInput}
              type="file"
              accept={acceptableImageExtensions}
              onChange={onClick}
              ref={inputRef}
              tabIndex={-1}
            />
            <label htmlFor={inputId} className={styles.uploadLabel} />
            {!isMobile && (
              <div className={styles.pictureWrapper}>
                <div className={styles.iconOval}>
                  <Camera />
                </div>
              </div>
            )}
          </div>
        )}
      </button>
    </div>
  );
};

export default AvatarInput;
